<template>
  <v-dialog :value="value" persistent width="1000">
    <v-card class="grey lighten-3">
      <v-toolbar flat dark color="#00aff0">
        <v-toolbar-title> Edit {{ attr.name }} </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container class="pa-3 ma-0">
        <v-row align="center" justify="center">
          <v-col cols="3">
            <component
              :is="attrComponent"
              :attr="attr"
              :attrvalue="selected"
              :attrvalues="attrvalues"
              :disabled="disableInput.status"
              :disabledHint="disableInput.message"
              @save-attr="setNewValue"
              no-focus
            >
            </component>
          </v-col>
          <v-col cols="3" class="mt-6">
            <v-menu
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              nudge-left="30"
              nudge-top="25"
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  background-color="#fff"
                  label="Start Date"
                  clearable
                  @click:clear="startDate = null"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  :disabled="selected && statusTooltip(selected) === 'Expired'"
                  v-bind="attrs"
                  v-on="on"
                  :value="formattedStartDate"
                />
              </template>
              <v-date-picker v-model="startDate" show-current no-title />
            </v-menu>
          </v-col>
          <v-col cols="3" class="mt-6">
            <v-menu
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              nudge-left="30"
              nudge-top="25"
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  background-color="#fff"
                  label="End Date"
                  clearable
                  @click:clear="endDate = null"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  :disabled="selected && statusTooltip(selected) === 'Expired'"
                  v-bind="attrs"
                  v-on="on"
                  :value="formattedEndDate"
                />
              </template>
              <v-date-picker v-model="endDate" show-current no-title />
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
      <v-divider />
      <v-data-table
        v-if="attrvalues.length > 0"
        :items="attrvalues"
        single-select
        :show-select="hasWriteAccess"
        @item-selected="selectRow"
        :headers="headers"
        height="300"
        no-data-text="No Activity to Display"
        sort-by="start_dt"
        hide-default-footer
        dense
      >
        <template v-slot:[`item.status`]="{ item }">
          <v-tooltip v-if="attrvalue" top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" right :color="statusColor(item)">
                {{ statusIcon(item) }}
              </v-icon>
            </template>
            <span>{{ statusTooltip(item) }}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.value`]="{ item }">
          <span>{{ displayValue(item) }}</span>
        </template>

        <template v-slot:[`item.start_dt`]="{ item }">
          <span>
            {{ moment(item.start_dt).format($config.date_display) }}
          </span>
        </template>

        <template v-slot:[`item.end_dt`]="{ item }">
          <span v-if="item.end_dt">
            {{ moment(item.end_dt).format($config.date_display) }}
          </span>
          <span v-else>
            ---
          </span>
        </template>

        <template v-slot:[`item.updated_on`]="{ item }">
          <span>
            {{ moment(item.updated_on).format($config.date_display) }}
          </span>
        </template>

        <template v-slot:[`item.updated_by`]="{ item }">
          <span>{{ item.updated_by }}</span>
        </template>
      </v-data-table>
      <v-toolbar flat class="grey lighten-3">
        <v-spacer />
        <v-btn v-if="showDelete" color="red" class="white--text mr-4" @click="openDeleteDialog()">
          {{ 'Delete' }}
          <v-icon right>
            {{ 'mdi-trash-can' }}
          </v-icon>
        </v-btn>
        <v-btn
          color="green"
          class="white--text"
          :disabled="saveBtnDisabled"
          @click="selected ? updateAttributeValue() : saveAttributeValue()"
        >
          {{ selected ? 'Update' : 'Save' }}
          <v-icon right>
            {{ selected ? 'mdi-update' : 'mdi-content-save-outline' }}
          </v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>

    <v-dialog v-model="deleteDialog" max-width="450px">
      <v-card>
        <v-card-title>Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="closeDeleteDialog">Cancel</v-btn>
          <v-btn color="primary" class="white--text" @click="deleteAttributeValue">Confirm</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>
import attrApi from '@/axios/party-attribute-endpoint'
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'

export default {
  name: 'TimeSensitiveInput',
  data: () => ({
    selected: null,
    startDate: null,
    endDate: null,
    newVal: null,
    saveBtnDisabled: false,
    deleteDialog: false
  }),
  props: ['value', 'attr', 'attrvalue', 'attrvalues', 'attrComponent', 'defaultStartDate'],
  mixins: [displayAlert, userAccess],
  watch: {
    value: {
      handler(value) {
        if (!value) {
          this.$emit('refresh-values')
          this.saveBtnDisabled = false
        }
      }
    }
  },
  computed: {
    minEndDate() {
      return this.moment(this.startDate)
        .add(1, 'days')
        .format(this.$config.api_date_format)
    },
    isKeyValue() {
      return this.attr.data_type.constant === 'KEY_VALUE'
    },
    isDateValue() {
      return this.attr.data_type.constant === 'DATE'
    },
    showDelete() {
      return this.selected && this.selected?.id !== this.attrvalue?.id && this.statusTooltip(this.selected) !== 'Expired'
    },
    formattedStartDate() {
      return this.startDate ? this.moment(this.startDate).format(this.$config.date_display) : null
    },
    disableInput() {
      if (this.selected && this.statusTooltip(this.selected) !== 'Upcoming') {
        return { status: true, message: `Cannot change ${this.statusTooltip(this.selected)} attributes`}
      } else {
        return { status: false, message: ''}
      }
    },
    formattedEndDate() {
      return this.endDate ? this.moment(this.endDate).format(this.$config.date_display) : null
    },
    hasExistingValues() {
      return this.attrvalues.length >= 1
    },
    maxAttrDate() {
      return this.attrvalues.reduce((a, b) => (a.start_dt > b.start_dt ? a : b))
    },
    headers() {
      return [
        {
          text: 'Status',
          value: 'status',
          class: 'black--text',
          sortable: false
        },
        {
          text: 'Value',
          value: this.isKeyValue ? 'attr_key_value.name' : 'value',
          class: 'black--text',
          sortable: false
        },
        {
          text: 'Start Date',
          value: 'start_dt',
          class: 'black--text',
          sortable: false
        },
        {
          text: 'End Date',
          value: 'end_dt',
          class: 'black--text',
          sortable: false
        },
        {
          text: 'Updated On',
          value: 'updated_on',
          class: 'black--text',
          sortable: false
        },
        {
          text: 'Updated By',
          value: 'updated_by',
          class: 'black--text',
          sortable: false
        }
      ]
    }
  },
  methods: {
    isActiveDate(start, end) {
      const current_dt = new Date()
      const start_dt = new Date(start)
      const end_dt = end ? new Date(end) : null
      return start_dt <= current_dt && (end_dt > current_dt || !end_dt)
    },
    isUpcomingDate(start) {
      const current_dt = new Date()
      const start_dt = new Date(start)
      return start_dt > current_dt
    },
    setNewValue(val) {
      this.newVal = this.isKeyValue ? val.id : val
    },
    selectRow(row) {
      if (row.value) {
        if (this.isKeyValue) {
          this.newVal = row.item ? row.item.attr_key_value?.id : this.attrvalue.attr_key_value?.id
        } else {
          this.newVal = row.item ? row.item.value : this.attrvalue.value
        }
        this.selected = row.item
        this.startDate = row.item.start_dt
        this.endDate = row.item.end_dt
        this.saveBtnDisabled = this.statusTooltip(this.selected) == 'Expired'
      } else {
        this.clearSelected()
        this.saveBtnDisabled = false
      }
    },
    clearSelected() {
      this.newVal = null
      this.selected = null
      this.startDate = null
      this.endDate = null
    },
    displayValue(item) {
      if (this.isKeyValue) {
        return item.attr_key_value.name
      } else if (this.date) {
        return this.moment(item.value).format(this.$config.date_display)
      } else {
        return item.value
      }
    },
    statusIcon(item) {
      if (this.isActiveDate(item.start_dt, item.end_dt)) {
        return 'mdi-checkbox-marked-circle-outline'
      } else if (this.isUpcomingDate(item.start_dt)) {
        return 'mdi-calendar-clock'
      } else {
        return 'mdi-close-circle-outline'
      }
    },
    statusColor(item) {
      if (this.isActiveDate(item.start_dt, item.end_dt)) {
        return 'green'
      } else if (this.isUpcomingDate(item.start_dt)) {
        return 'orange'
      } else {
        return 'red'
      }
    },
    statusTooltip(item) {
      if (this.isActiveDate(item.start_dt, item.end_dt)) {
        return 'Active'
      } else if (this.isUpcomingDate(item.start_dt)) {
        return 'Upcoming'
      } else {
        return 'Expired'
      }
    },
    openDeleteDialog() {
      this.deleteDialog = true
    },
    closeDeleteDialog() {
      this.deleteDialog = false
    },
    async deleteAttributeValue() {
      this.closeDeleteDialog()
      try {
        await attrApi.attributeValue.delete(this.selected?.id)
        this.$emit('refresh-values')
        this.emitAlert(true, 'success', `${this.attr.name} has been successfully deleted.`)
        this.clearSelected()
      } catch (err) {
        this.$emit('refresh-values')
        this.handleError(err)
      }
    },
    async updateAttributeValue() {
      this.saveBtnDisabled = true
      if (
        (!this.newVal || this.newVal === '' ) ||
        !this.startDate
      ) {
        this.saveBtnDisabled = false
        return this.emitAlert(true, 'warning', 'Please fill out required fields.')
      }

      var attrValue = {
        attr_id: this.attr.id,
        attr_key_value_id: null,
        party_id: this.$route.query.id,
        start_dt: this.startDate,
        end_dt: this.endDate,
        updated_by: this.$store.getters.email,
        value: null
      }
      if (this.isKeyValue) {
        attrValue.attr_key_value_id = this.newVal ? this.newVal : this.selected?.id
      } else {
        attrValue.value = this.newVal ? this.newVal : this.selected.value
      }

      try {
        await attrApi.attributeValue.put(this.selected?.id, attrValue)
        this.clearSelected()
        this.$emit('refresh-values')
        this.emitAlert(true, 'success', `${this.attr.name} has been successfully updated.`)
        this.saveBtnDisabled = false
      } catch (err) {
        this.$emit('refresh-values')
        this.handleError(err)
        this.saveBtnDisabled = false
      }
    },
    async saveAttributeValue() {
      this.saveBtnDisabled = true
      if (!this.newVal || !this.startDate) {
        this.saveBtnDisabled = false
        return this.emitAlert(true, 'warning', 'Please fill out required fields.')
      }
      
      this.selected = {}
      var attrValue = {
        attr_id: this.attr.id,
        attr_key_value_id: this.isKeyValue ?  this.newVal : null,
        created_by: this.$store.getters.email,
        party_id: this.$route.query.id,
        start_dt: this.startDate,
        end_dt: this.endDate,
        value: !this.isKeyValue ? this.newVal : null,
      }

      try {
        await attrApi.attributeValue.post(attrValue)
        this.clearSelected()
        this.$emit('refresh-values')
        this.emitAlert(true, 'success', `${this.attr.name} has been successfully saved.`)
        this.saveBtnDisabled = false
      } catch (err) {
        this.$emit('refresh-values')
        this.handleError(err)
        this.saveBtnDisabled = false
      }
    }
  }
}
</script>
